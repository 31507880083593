@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
  width: 100vw;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 100vh;
  background-color: #1e2129;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
